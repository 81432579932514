.fillerLarge {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.fillerSmall {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.filler {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

img.grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

img.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}

img.rounded {
  border-radius: 5%;
}

/* .parallax-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.parallax-container::-webkit-scrollbar { 
  display: none;
} */

ul.checklist {
  list-style: none;
  padding-left: 0;
}

li.checklist {
  position: relative;
  padding-left: 1.5em;
  /* space to preserve indentation on wrap */
}

ul.checklist li.checklist:before {
  content: '✓';
  position: absolute;
  left: 0;
  /* place the SVG at the start of the padding */
  width: 1em;
  height: 1em;
}

@media all and (max-width: 850px) {
  .hide_mobile {
    display: none;
  }
}